import { gql } from "@apollo/client";
import GeneralCardFragment from "../GeneralListing/GeneralCard.fragment";
import SingleFeatureCardParagraphFragment from "./SingleFeatureCard.fragment";

export default gql`
  ${GeneralCardFragment}
  ${SingleFeatureCardParagraphFragment}
  fragment FeatureCardVariant on FeatureCard2_3Paragraph {
    id
    featureCardTitle
    singleFeatureCards {
      ...SingleFeatureCardFragment
    }
    colourTheme
  }
`;
