import { gql } from "@apollo/client";
import TodayAtTheZooCardFragment from "../FeatureCard/TodayAtTheZooCard.fragment";

export default gql`
  ${TodayAtTheZooCardFragment}
  fragment TodayAtTheZooFragment on TodayAtTheZooListingParagraph {
    id
    title
    schedule {
      ...TodayAtTheZooCardFragment
    }
  }
`;
