import { gql } from "@apollo/client";

export default gql`
  fragment TextImageFragment on TextImageParagraph {
    id
    textImageLayout
    title
    text {
      format
      processed
      value
    }
    image {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    caption
    callToAction {
      title
      url
      target
    }
  }
`;
