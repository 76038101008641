import { gql } from "@apollo/client";

export default gql`
  fragment ImageItemFragment on ImageItemParagraph {
    id
    image {
      id
      copyright
      altText
      url
      sm
      md
      lg
      xl
    }
  }
`;
