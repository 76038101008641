import { gql } from "@apollo/client";
import GallerySlide from "./GallerySlide.fragment";

export default gql`
  ${GallerySlide}
  fragment ImageGalleryFragment on ImageGalleryParagraph {
    id
    gallerySlides {
      ...GallerySlideFragment
    }
  }
`;
