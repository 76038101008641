import { gql } from "@apollo/client";
import GeneralCardFragment from "../GeneralListing/GeneralCard.fragment";

export default gql`
  ${GeneralCardFragment}
  fragment SingleFeatureCardFragment on SingleFeatureCardParagraph {
    id
    cards {
      ...GeneralCardFragment
    }
    primaryCtaLabel
    secondaryCta {
      url
      title
      target
    }
  }
`;
