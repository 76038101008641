import { gql } from "@apollo/client";

export default gql`
  fragment CallToActionItemFragment on CallToActionItemParagraph {
    id
    title
    backgroundImage {
      id
      copyright
      altText
      url
      sm
      md
      lg
      xl
    }
    callToAction {
      title
      url
      target
    }
  }
`;
