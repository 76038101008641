import AccordionFragment from "@/components/Accordion/Accordion.fragment";
import CarouselFragment from "@/components/Carousel/Carousel.fragment";
import { DonateFragment } from "@/components/Donate/Donate.fragment";
import DownloadButtonsFragment from "@/components/DownloadButtons/DownloadButtons.fragment";
import EventCardFragment from "@/components/EventCard/EventCard.fragment";
import EventListingFragment from "@/components/EventListing/EventListing.fragment";
import FeatureCardFragment from "@/components/FeatureCard/FeatureCard.fragment";
import FeatureCardVariantFragment from "@/components/FeatureCardVariant/FeatureCardVariant.fragment";
import SingleFeatureCardFragment from "@/components/FeatureCardVariant/SingleFeatureCard.fragment";
import FourPillarsFragment from "@/components/FourPillars/FourPillars.fragment";
import GeneralCardFragment from "@/components/GeneralListing/GeneralCard.fragment";
import GeneralListingFragment from "@/components/GeneralListing/GeneralListing.fragment";
import ImageComparisonFragment from "@/components/ImageComparison/ImageComparison.fragment";
import GallerySlideFragment from "@/components/ImageGallery/GallerySlide.fragment";
import ImageGalleryFragment from "@/components/ImageGallery/ImageGallery.fragment";
import ImagesFragment from "@/components/Images/Images.fragment";
import ImpactBlockFragment from "@/components/ImpactBlock/ImpactBlock.fragment";
import InfoBlockFragment from "@/components/InfoBlock/InfoBlock.fragment";
import InfographicFragment from "@/components/Infographic/Infographic.fragment";
import InPageTabsFragment from "@/components/InPageTabs/InPageTabs.fragment";
import NewsletterSignUpBlockFragment from "@/components/NewsletterSignUpBlock/NewsletterSignUpBlock.fragment";
import PillarFragment from "@/components/Pillar/Pillar.fragment";
import ProjectInDetailSliderFragment from "@/components/ProjectInDetailSlider/ProjectInDetailSlider.fragment";
import PromoBlockFragment from "@/components/PromoBlock/PromoBlock.fragment";
import SpeciesBlockFragment from "@/components/SpeciesBlock/SpeciesBlock.fragment";
import TestimonialBlockQuoteFragment from "@/components/TestimonialBlockQuote/TestimonialBlockQuote.fragment";
import TextImageFragment from "@/components/TextImage/TextImage.fragment";
import VideoComponentFragment from "@/components/VideoComponent/VideoComponent.fragment";
import { VoucherFragment } from "@/components/Voucher/Voucher.fragment";
import WebformFragment from "@/components/Webform/Webform.fragment";
import WYSIWYGFragment from "@/components/WYSIWYG/WYSIWYG.fragment";
import { gql } from "@apollo/client";
import { CampaignHeroFragment, DonateHeroFragment, ShortHeroFragment, TallHeroFragment, VideoHeroFragment, VoucherHeroFragment } from "@components/Hero/Hero.fragment";

import TodayAtTheZooFragment from "@components/TodayAtZooListing/TodayAtZooListing.fragment";

export default gql`
  ${ShortHeroFragment}
  ${VideoHeroFragment}
  ${TallHeroFragment}
  ${CampaignHeroFragment}
  ${DonateHeroFragment}
  ${WYSIWYGFragment}
  ${ImpactBlockFragment}
  ${PromoBlockFragment}
  ${AccordionFragment}
  ${InfographicFragment}
  ${ProjectInDetailSliderFragment}
  ${NewsletterSignUpBlockFragment}
  ${FeatureCardFragment}
  ${CarouselFragment}
  ${DownloadButtonsFragment}
  ${SpeciesBlockFragment}
  ${ImagesFragment}
  ${GeneralListingFragment}
  ${ImageGalleryFragment}
  ${WebformFragment}
  ${InPageTabsFragment}
  ${FourPillarsFragment}
  ${InfoBlockFragment}
  ${FeatureCardVariantFragment}
  ${TestimonialBlockQuoteFragment}
  ${EventListingFragment}
  ${TextImageFragment}
  ${ImageComparisonFragment}
  ${VideoComponentFragment}
  ${DonateFragment}
  ${EventCardFragment}
  ${GallerySlideFragment}
  ${GeneralCardFragment}
  ${PillarFragment}
  ${SingleFeatureCardFragment}
  ${TodayAtTheZooFragment}
  ${VoucherHeroFragment}
  ${VoucherFragment}
  fragment CampaignPageFragment on CampaignNode {
    id
    created
    previewEditUrl
    latestVersion
    versionStatus
    published
    hero {
      ...ShortHeroParagraph
      ...VideoHeroParagraph
      ...TallHeroParagraph
      ...CampaignHeroParagraph
      ...DonateHeroParagraph
      ...VoucherHeroParagraph
    }
    metatags {
      metaTitle
      metaRobots
      metaImage {
        md
      }
      metaDescription
      canonical
    }
    breadcrumbs {
      title
      url
    }
    path
    title
    displayLastModified
    lastRevised
    content {
      ...WYSIWYGFragment
      ...ImpactBlockFragment
      ...PromoBlockFragment
      ...AccordionFragment
      ...InfographicFragment
      ...ProjectInDetailSliderFragment
      ...NewsletterSignUpBlockFragment
      ...FeatureCardFragment
      ...CarouselFragment
      ...DownloadFragment
      ...SpeciesFragment
      ...ImagesFragment
      ...GeneralListingFragment
      ...ImageGalleryFragment
      ...WebformFragment
      ...InPageTabsFragment
      ...FourPillarsFragment
      ...InfoBlockFragment
      ...FeatureCardVariant
      ...TestimonialsFragment
      ...EventListingFragment
      ...TextImageFragment
      ...ImageComparisonFragment
      ...VideoComponentFragment
      ...DonateFragment
      ...EventCardFragment
      ...GallerySlideFragment
      ...GeneralCardFragment
      ...PillarFragment
      ...SingleFeatureCardFragment
      ...TodayAtTheZooFragment
      ...VoucherFragment
    }
    socialLinks {
      twitter
      facebook
      linkedin
    }
    language
    languageVariants {
      url
      language
    }
  }
`;
