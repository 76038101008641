import { InfoBlockLinkFragmentDoc } from "@/__generated__/graphql";
import { gql } from "@apollo/client";

export default gql`
 ${InfoBlockLinkFragmentDoc}
  fragment InfoBlockFragment on InfoBlockParagraph {
    id
    infoBlockLinks {
      ...InfoBlockLinkFragment
    }
  }
`;
