import { gql } from "@apollo/client";
import GeneralCardFragment from "./GeneralCard.fragment";

export default gql`
  ${GeneralCardFragment}
  fragment GeneralListingFragment on GeneralListingParagraph {
    id
    title
    callToAction {
      url
      title
      target
    }
    cards {
      ...GeneralCardFragment
    }
    description {
      processed
      value
      format
    }
    showDescription
    showEyebrow
  }
`;
