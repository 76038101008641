import { gql } from "@apollo/client";

export default gql`
  fragment PillarCardFragment on PillarCardParagraph {
    id
    title
    circleImage {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    backgroundImage {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    description
    cta
  }
`;
