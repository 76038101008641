import { gql } from "@apollo/client";

export default gql`
  fragment ImagesFragment on ImagesParagraph {
    id
    imageLayout
    caption
    leftImage {
      id
      copyright
      altText
      url
      sm
      md
      lg
      xl
    }
    rightImage {
      id
      copyright
      altText
      url
      sm
      md
      lg
      xl
    }
  }
`;
