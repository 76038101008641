import { gql } from '@apollo/client';

export const VoucherFixedFragment = gql`
  fragment VoucherFixedAmountParagraph on VoucherFixedAmountParagraph {
    id
    title
    description
    amount
    callToAction {
      url
      title
      target
    }
  }
`;

export const VoucherMultipleFragment = gql`
  fragment VoucherMultipleAmountsParagraph on VoucherMultipleAmountsParagraph {
    id
    title
    description
    callToAction {
      url
      title
      target
    }
    singleVoucher {
      options {
        label
        value
        isDefault
        description
      }
      showFieldOther
    }
  }
`;

export const VoucherFragment = gql`
  ${VoucherFixedFragment}
  ${VoucherMultipleFragment}
  fragment VoucherFragment on VoucherParagraph {
    id
    content {
      ...VoucherFixedAmountParagraph
      ...VoucherMultipleAmountsParagraph
    }
  }
`;
