import { gql } from "@apollo/client";

export default gql`
  fragment QuoteItemFragment on QuoteItemParagraph {
    id
    content {
      processed
      value
      format
    }
    author
  }
`;
