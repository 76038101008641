import { gql } from "@apollo/client";

export default gql`
  fragment TodayAtTheZooCardFragment on TodayAtTheZooCardParagraph {
    id
    title
    startDate
    path
    teaserImage {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    teaserDescription
    teaserEyebrow
  }
`;
