import { gql } from "@apollo/client";
import TodayAtTheZooCardFragment from "./TodayAtTheZooCard.fragment";

export default gql`
  ${TodayAtTheZooCardFragment}
  fragment FeatureCardFragment on FeatureCardParagraph {
    id
    featureCardTitle
    cards {
      ...GeneralCardFragment
      ...TodayAtTheZooCardFragment
    }
    backgroundColour
    primaryCtaLabel
    secondaryCta {
      url
      title
      target
    }
  }
`;
