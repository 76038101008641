import { gql } from "@apollo/client";

export default gql`
  fragment GeneralCardFragment on GeneralCardParagraph {
    id
    title
    path
    target
    teaserEyebrow
    teaserDescription
    teaserImage {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
  }
`;
