import { gql } from '@apollo/client';
import { DonateFragment } from "@components/Donate/Donate.fragment";
import { VoucherFragment } from "@components/Voucher/Voucher.fragment";

export default gql`
  ${DonateFragment}
  ${VoucherFragment}
  fragment PromoBlockFragment on PromoBlockParagraph {
    id
    promoTitle
    backgroundImage {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    description {
      processed
      value
      format
    }
    cta {
      title
      url
      target
    }
    circleMotifPosition
    donationBlock {
      ...DonateFragment
    }
    voucherBlock {
      ...VoucherFragment
    }
  }
`;
