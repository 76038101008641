import { gql } from "@apollo/client";

export default gql`
  fragment NewsletterSignUpBlockFragment on NewsletterSignUpBlockParagraph {
    id
    title
    supportingText
    callToAction {
      title
      url
      target
    }
  }
`;
