import { gql } from '@apollo/client';

export default gql`
  fragment EventCardFragment on EventCardParagraph {
    id
    title
    description
    image {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    cta {
      url
      title
      target
    }
  }
`;
