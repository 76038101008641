import { gql } from "@apollo/client";



export default gql`
  fragment SearchPageFragment on SearchPage {
    metatags {
      metaTitle
      metaRobots
      metaImage {
        md
      }
      metaDescription
      canonical
    }
    title
    subtitle
    mostSearchedFor
    searchResults {
      total
      pageSize
      page
      totalPages
      keywords
      resultList {
        id
        title
        path
        teaserImage {
          id
          sm
          md
          lg
          xl
          url
          altText
        }
        teaserEyebrow
        teaserDescription
      }
      didYouMean
    }
  }
`;
