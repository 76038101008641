import { gql } from "@apollo/client";

export default gql`
  fragment GallerySlideFragment on GallerySlideParagraph {
    id
    title
    gallerySlide {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    gallerySlideCaption
  }
`;