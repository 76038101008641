import { gql } from "@apollo/client";

export const InformationSectionFragment = gql`
  fragment InformationSection on InformationSection {
    tiles {
      label
      value
    }
    people {
      title
      list {
        url
        name
        jobTitle
        image {
          id
          copyright
          altText
          url
          sm
          md
          lg
          xl
        }
      }
    }
  }
`;
