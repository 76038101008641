import { gql } from "@apollo/client";

export default gql`
  fragment ImageComparisonFragment on ImageComparisonParagraph {
    id
    leftImage {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
    rightImage {
      id
      altText
      url
      sm
      md
      lg
      xl
    }
  }
`;
