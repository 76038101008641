import { gql } from "@apollo/client";

export default gql`
  fragment AccordionFragment on AccordionParagraph {
    id
    alignment
    tabs {
      id
      title
      content {
        processed
        format
        value
      }
    }
  }
`;
