import { gql } from "@apollo/client";
import PillarCardFragment from "./PillarCard.fragment";

export default gql`
  ${PillarCardFragment}
  fragment FourPillarsFragment on PillarParagraph {
    id
    title
    pillarCards {
      ...PillarCardFragment
    }
  }
`;
